// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartament-2-osobowy-de-js": () => import("./../../../src/pages/apartament-2-osobowy.de.js" /* webpackChunkName: "component---src-pages-apartament-2-osobowy-de-js" */),
  "component---src-pages-apartament-2-osobowy-en-js": () => import("./../../../src/pages/apartament-2-osobowy.en.js" /* webpackChunkName: "component---src-pages-apartament-2-osobowy-en-js" */),
  "component---src-pages-apartament-2-osobowy-js": () => import("./../../../src/pages/apartament-2-osobowy.js" /* webpackChunkName: "component---src-pages-apartament-2-osobowy-js" */),
  "component---src-pages-apartament-3-osobowy-2-pokojowy-de-js": () => import("./../../../src/pages/apartament-3-osobowy-2-pokojowy.de.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-2-pokojowy-de-js" */),
  "component---src-pages-apartament-3-osobowy-2-pokojowy-en-js": () => import("./../../../src/pages/apartament-3-osobowy-2-pokojowy.en.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-2-pokojowy-en-js" */),
  "component---src-pages-apartament-3-osobowy-2-pokojowy-js": () => import("./../../../src/pages/apartament-3-osobowy-2-pokojowy.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-2-pokojowy-js" */),
  "component---src-pages-apartament-3-osobowy-de-js": () => import("./../../../src/pages/apartament-3-osobowy.de.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-de-js" */),
  "component---src-pages-apartament-3-osobowy-en-js": () => import("./../../../src/pages/apartament-3-osobowy.en.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-en-js" */),
  "component---src-pages-apartament-3-osobowy-js": () => import("./../../../src/pages/apartament-3-osobowy.js" /* webpackChunkName: "component---src-pages-apartament-3-osobowy-js" */),
  "component---src-pages-apartament-4-osobowy-de-js": () => import("./../../../src/pages/apartament-4-osobowy.de.js" /* webpackChunkName: "component---src-pages-apartament-4-osobowy-de-js" */),
  "component---src-pages-apartament-4-osobowy-en-js": () => import("./../../../src/pages/apartament-4-osobowy.en.js" /* webpackChunkName: "component---src-pages-apartament-4-osobowy-en-js" */),
  "component---src-pages-apartament-4-osobowy-js": () => import("./../../../src/pages/apartament-4-osobowy.js" /* webpackChunkName: "component---src-pages-apartament-4-osobowy-js" */),
  "component---src-pages-apartament-5-osobowy-de-js": () => import("./../../../src/pages/apartament-5-osobowy.de.js" /* webpackChunkName: "component---src-pages-apartament-5-osobowy-de-js" */),
  "component---src-pages-apartament-5-osobowy-en-js": () => import("./../../../src/pages/apartament-5-osobowy.en.js" /* webpackChunkName: "component---src-pages-apartament-5-osobowy-en-js" */),
  "component---src-pages-apartament-5-osobowy-js": () => import("./../../../src/pages/apartament-5-osobowy.js" /* webpackChunkName: "component---src-pages-apartament-5-osobowy-js" */),
  "component---src-pages-cennik-de-js": () => import("./../../../src/pages/cennik.de.js" /* webpackChunkName: "component---src-pages-cennik-de-js" */),
  "component---src-pages-cennik-en-js": () => import("./../../../src/pages/cennik.en.js" /* webpackChunkName: "component---src-pages-cennik-en-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-de-js": () => import("./../../../src/pages/polityka-prywatnosci.de.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-de-js" */),
  "component---src-pages-polityka-prywatnosci-en-js": () => import("./../../../src/pages/polityka-prywatnosci.en.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-en-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-de-js": () => import("./../../../src/pages/regulamin.de.js" /* webpackChunkName: "component---src-pages-regulamin-de-js" */),
  "component---src-pages-regulamin-en-js": () => import("./../../../src/pages/regulamin.en.js" /* webpackChunkName: "component---src-pages-regulamin-en-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */)
}

